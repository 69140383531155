.sideNavMenu {
  height: 100vh;
  background-color: #fff;
  width: 225px;

  
}

.sideNavMenu :global(.ant-menu) {
    background-color: #fff;
  
}

.sideNavMenu :global(.ant-menu-inline), .sideNavMenu :global(.ant-menu-item) {
  border-right: 1px solid #ccc;
}

.sideNavMenu :global(.ant-menu-item:hover) , .sideNavMenu :global(.ant-menu-submenu-title:hover) {
  /* background-color: rgba(38,141,221,.1); */
  /* background-color: #997733; */
  background-color: #eef3ff;
  border-right: 1px solid #555;
  transition: border-color 1s, background .3s, padding .15s cubic-bezier(0.645,.045,.355,1);
}
  /* background-color: #0756ea; */

.sideNavMenu :global(.ant-menu:not(.ant-menu-horizontal)) :global(.ant-menu-item-selected) {
  background-color: #eef3ff;
  
}

.sideNavMenu :global(.ant-menu-item-selected), 
.sideNavMenu :global(.ant-menu-item-selected a), 
.sideNavMenu :global(.ant-menu-item-selected a:hover),
.sideNavMenu :global(.ant-menu-item a:hover) {
  color: #0756ea;
  /* border-right:2px solid #0756ea; */
}

.sideNavMenuBrandName {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
 
  margin-bottom: 00px;

  font-weight: 500;
  font-size: 18px;
}