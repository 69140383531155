body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#pdfPreview p {
  margin-bottom: 8px;
}

@font-face {
  font-family: 'TH Niramit AS';
  src: url(./assets/fonts/Niramit/TH\ Niramit\ AS.ttf) format('truetype');
}
@font-face {
  font-family: 'TH Niramit AS Bold';
  src: url(./assets/fonts/Niramit/TH\ Niramit\ AS\ Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'TH Niramit AS Italic';
  src: url(./assets/fonts/Niramit/TH\ Niramit\ AS\ Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'TH Niramit AS Bold Italic';
  src: url(./assets/fonts/Niramit/TH\ Niramit\ AS\ Bold\ Italic.ttf) format('truetype');
}
.sun-editor-editable table td, .sun-editor-editable table th{
  border: 1px solid #000 !important;
}
.sun-editor-editable p{
  text-indent: 25px;
}