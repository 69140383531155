.main-container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 70px);
}
.content{
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 70px);
}
.upload-container{
    display: flex;
    flex: 2;
    flex-direction: column;
    min-height: calc(100vh - 70px);
}
.preview-container{
    display: flex;
    flex: 3;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-left: 10px;
    padding: 0px 10px;
    border-radius: 5px;
    box-shadow: 2px 3px 2px 0 darkgrey;
    min-height: calc(100vh - 70px);
}
.upload-button{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 10px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 3px 2px 0 darkgrey;
}
.report-param{
    display: flex;
    flex: 3;
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    /* background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 3px 2px 0 darkgrey;
    width: 100%; */
}
.report-param-component{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}
.action-container{
    display: flex;
    flex: 3;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 3px 2px 0 darkgrey;
    width: 100%;
}
.action-button{
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    width: 100%;
}
.save-btn-container{
    display: flex;
    flex: 1;
    flex-direction: row;
}
.status-container{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
}
.status-container-span{
    margin: auto 2px;
}
.select-option-CANCEL{
    color: red;
}
.select-option-PUBLISH{
    color: green;
}