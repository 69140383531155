

.sideNavModule {
  height: 100vh;
  /* background-color: #1665C0; */
  background-color: #0033c4;
  width: 60px; 

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* color: #3d87db */
}

.sideNavModuleMenuWrapper {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideNavModuleFooterWrapper {
  margin-bottom: 20px;
}

.sideNavModuleMenuIcon {
  padding: 10px 10px 3px 10px;
  
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;


  background-color: #3269ff;
  cursor: pointer;
}

.sideNavModuleIcon {
  padding: 8px 10px 3px 10px;
  
  margin-bottom: 10px;
  border-radius: 8px;
}

.sideNavModuleIcon:hover {
  /* background-color: #3d87db; */
  background-color: #ff0854;
  border: #ff0854;
  cursor: pointer;
}

.sideNavModuleIconActive {
  padding: 8px 10px 3px 10px;
  
  margin-bottom: 10px;
  border-radius: 8px;
  
  /* background-color: #3d87db; */
  background-color: #ff0854;
  border: #ff0854;
  cursor: pointer;
}


/* .sideNavModule span {
  color: #fff;
  cursor: pointer;
  
  
} */