.main-container{
    display: flex;
    position: relative;
    flex-direction: column;
    height: calc(100vh - 60px);
    overflow-y: auto;
}
.editor-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.text-area-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.search-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 3px 2px 0 darkgrey;
}
.search-component{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.list-container{
    display: flex;
    flex-direction: column;
    flex: 3;
    margin-top: 10px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 3px 2px 0 darkgrey;
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 50px;
    flex-direction: column;
    flex: 1;

}

.search-text-container{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 50px;
    flex-direction: column;
    flex: 1;

}
.table-container{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex: 1;
}
.data-tabel{
    width: 100%;
}

.action-icons{
    font-size: 20px;
    margin: 0px 5px;
}
.editor {
    position: relative;
    min-height: 275px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
    background-color: white;
}
.preview {
    display: flex;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0px;
    top: 0px;
    z-index: 100;
    width: 100%;
    height: 100%;
    padding: 20px 0px;
    overflow-y: auto;
}
.closePreview{
    display: flex;
    position: fixed;
    right: 20px;
    top: 20px;
    cursor: pointer;
    color: #fafafa
}
.closePreview:hover{
    color: #1890ff;
    text-decoration: underline;
}
.managementContainer{
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0px;
    top: 50px;
    width: 120px;
    height: 100%;
    padding: 5px;
    justify-content: flex-start;
    align-items: flex-start;
}

@keyframes manageButton-hover {
    from{
        scale: 1;
    }
    to{
        scale: 1.05;
    }
}
.manageButton{
    display: flex;
    flex-direction: column;
    width: 90px;
    height: 100px;
    justify-content: center;
    align-items: center;
    border: 1px black solid;
    border-radius: 5px;
    margin: 5px 0px;
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 0px 3px 1px darkgray;

}
.manageButton:hover{
    border: 1px #1890ff solid;
    color: #1890ff;
    box-shadow: 0px 0px 10px 1px #1890ff;
    scale: 1.05;
    animation: manageButton-hover 0.5s;
}
/* .manageButton:active{
    border: 1px #1890ff solid;
    color: #1890ff;
    box-shadow: 0px 0px 7px 1px #1890ff;
    scale: 1.02;
} */