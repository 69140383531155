.main-container{
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 70px);
}

.search-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 3px 2px 0 darkgrey;
}
.search-component{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.list-container{
    display: flex;
    flex-direction: column;
    flex: 3;
    margin-top: 10px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 3px 2px 0 darkgrey;
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 50px;
    flex-direction: column;
    flex: 1;

}

.search-text-container{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 50px;
    flex-direction: column;
    flex: 1;

}
.table-container{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex: 1;
}
.data-tabel{
    width: 100%;
}

.action-icons{
    font-size: 20px;
    margin: 0px 5px;
}
